// 优惠管理 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "promotionName",
        label: "活动名称",
    },
    {
        prop: "couponName",
        label: "优惠券名称",
    },
    {
        prop: "statusName",
        label: "状态",
        width: 100,
    },
    {
        prop: "amount",
        label: "面值",
        width: 100,
    },
    {
        prop: "useRule",
        label: "使用条件",
        width: 100,
    },
    {
        prop: "date",
        label: "有效期",
        width: 200,
    },
    {
        prop: "creater",
        label: "创建人",
        width: 100,
    },
    {
        prop: "createTime",
        label: "创建时间",
        width: 150,
    },
];